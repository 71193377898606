var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-layout", [
    _c("div", { staticClass: "gx-main-content-wrapper" }, [
      _c("div", { staticClass: "gx-login-container" }, [
        _c(
          "div",
          { staticClass: "gx-login-content" },
          [
            _c("div", { staticClass: "gx-login-header gx-text-center" }, [
              _c("h1", { staticClass: "gx-login-title" }, [
                _vm._v("Reset your password"),
              ]),
              _c("p", [_vm._v("Enter your new password")]),
            ]),
            _vm.status === "error"
              ? _c("a-alert", {
                  staticClass: "gx-mb-3",
                  attrs: { type: "error", message: _vm.message, banner: "" },
                })
              : _vm._e(),
            _c(
              "a-input",
              {
                staticClass: "gx-mb-3",
                attrs: {
                  type: _vm.showPassword ? "text" : "password",
                  placeholder: "New Password",
                },
                model: {
                  value: _vm.password,
                  callback: function ($$v) {
                    _vm.password = $$v
                  },
                  expression: "password",
                },
              },
              [
                _c("a-icon", {
                  staticStyle: { cursor: "pointer" },
                  attrs: {
                    slot: "suffix",
                    type: _vm.showPassword ? "eye" : "eye-invisible",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.togglePasswordVisibility(true)
                    },
                  },
                  slot: "suffix",
                }),
              ],
              1
            ),
            _c(
              "a-input",
              {
                staticClass: "gx-mb-3",
                attrs: {
                  type: _vm.showConfirmPassword ? "text" : "password",
                  placeholder: "Confirm Password",
                },
                model: {
                  value: _vm.cpassword,
                  callback: function ($$v) {
                    _vm.cpassword = $$v
                  },
                  expression: "cpassword",
                },
              },
              [
                _c("a-icon", {
                  staticStyle: { cursor: "pointer" },
                  attrs: {
                    slot: "suffix",
                    type: _vm.showConfirmPassword ? "eye" : "eye-invisible",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.togglePasswordVisibility()
                    },
                  },
                  slot: "suffix",
                }),
              ],
              1
            ),
            _c(
              "a-button",
              {
                attrs: { type: "primary", block: "", loading: _vm.isLoading },
                on: { click: _vm.resetPassword },
              },
              [_vm._v("Reset Password")]
            ),
            _c(
              "div",
              { staticClass: "gx-text-center" },
              [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Go back")])],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }